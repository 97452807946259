<template>
  <b-row class="match-height">
    <b-col md="6">
      <b-card-code no-body title="Update Learn and Develop toolkit category">
        <b-card-body>
          <validation-observer ref="toolkitCategoryValidation">
            <div class="mt-1">
              <label>Name</label>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  type="text"
                  placeholder="Category name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div class="mt-1">
              <label>Color</label>
                <b-form-input
                  v-model="color"
                  type="text"
                  placeholder="#FF0000"
                />
            </div>
          </validation-observer>

          <div class="d-flex mt-2 justify-content-end">
            <b-button variant="primary" @click="updateCategory">
              Update
            </b-button>
          </div>
        </b-card-body>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCardBody,
  BCardText,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BCardCode,
    BCardBody,
    BCardText,
    BFormInput,
    BRow,
    BCol,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      categoryId: this.$route.params.id,
      name: "",
      color: "",
      required,
    };
  },
  methods: {
    getToolkitCategories() {
      // Request to create toolkit
      this.$http
        .get("/api/auth/admin/toolkit-categories/" + this.$route.params.id)
        .then((res) => {
          this.name = res.data.name;
          this.color = res.data.color;
        })
        .catch((err) => {
          // If toolkit doesn't exist redirect user to create page
          if (err.response.status == 404) {
            this.$router.push({ name: "learn-and-develop-categories-create" });
          } else {
            console.log(err);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong, please login again",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
    updateCategory() {
      this.$refs.toolkitCategoryValidation.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          formData.append("name", this.name);
          formData.append("color", this.color);
          formData.append("_method", "PATCH");

          // Request to update toolkit category
          this.$http
            .post(
              "/api/auth/admin/toolkit-categories/" + this.categoryId,
              formData
            )
            .then((res) => {
              if (res != undefined) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "The toolkit category was successfully updated",
                    icon: "CheckSquareIcon",
                    variant: "success",
                  },
                });
              }

              // Redirect to toolkit categories list page
              this.$router.push({
                name: "learn-and-develop-categories",
              });
            })
            .catch((err) => {
              console.log(err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong, please login again",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
  mounted() {
    this.getToolkitCategories();
  },
};
</script>